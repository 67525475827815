import TopBar from './appbar/TopBar';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import FillSessions from './components/FillSessions';
import Stations from './components/Stations';
import VirtualKeypad from './components/VirtualKeypad';

export default function Entrypoint(props: { logout: () => void }) {
    return (
        <div>
            <TopBar logout={props.logout}>
                <Switch>
                    <Route path="/dashboard">
                        <Typography variant="h3" align="center">Select a tab on the left</Typography>
                    </Route>
                    <Route path="/virtual-keypad">
                        <VirtualKeypad />
                    </Route>
                    <Route path="/stations">
                        <Stations />
                    </Route>
                    <Route path="/logs">
                        <FillSessions />
                    </Route>
                    <Route>
                        <Redirect to="/dashboard" />
                    </Route>
                </Switch>
            </TopBar>
        </div>
    )
}
