import styles from "./virtualKeypad.module.css"
import { Button, IconButton, Paper } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import { gql, useApolloClient, useQuery } from "@apollo/client"
import { MutationVirtualKeypadArgs, StationStatus } from "../../../graphqltypes"
import { CircularProgress } from "@material-ui/core"
import { Snackbar } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { GET_STATION_STATUSES } from "../Stations"

export const SUBMIT_VIRTUAL_KEYPAD = gql`
mutation SubmitVirtualKeypad($station: String!, $pin: String!) {
  virtualKeypad(station: $station, pin: $pin)
}
`

export default function VirtualKeypad() {
    const client = useApolloClient()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState('')
    const [pin, setPin] = useState('')

    const { data: stationsData, startPolling, stopPolling } = useQuery<{ statuses: StationStatus[] }>(GET_STATION_STATUSES)

    useEffect(() => {
        startPolling(3000)
        return () => {
            stopPolling()
        }
    })

    const submit = async (station: string) => {
        try {
            setLoading(true)
            const result = await client.mutate<{ virtualKeypad: string }, MutationVirtualKeypadArgs>({
                mutation: SUBMIT_VIRTUAL_KEYPAD,
                variables: {
                    pin,
                    station
                },
                errorPolicy: 'all'
            })

            if (result?.errors?.length > 0) {
                return setAlert(result.errors[0].message)
            }

            if (result?.data?.virtualKeypad === "successful") {
                setAlert("Fill Session created")
            } else {
                setAlert("Unable to create fill session")
            }
        } finally {
            setLoading(false)
            setPin('')
        }
    }

    return (
        <div className={styles.holder}>
            <Paper>
                <TextField variant="outlined" disabled={loading} label="Pin" inputMode="numeric" inputProps={{ pattern: "[0-9]*" }} value={pin} onChange={(e) => setPin(e.target.value)} />
                <div className={styles.stationButtons}>
                {!loading ? stationsData?.statuses?.map(s => (
                    <Button variant="outlined" disabled={s.state !== "IDLE"} onClick={() => submit(s.station)}>Station {s.station}</Button>
                )) : (
                    <CircularProgress />
                )}
                </div>
            </Paper>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={alert.length > 0}
                autoHideDuration={6000}
                onClose={() => setAlert('')}
                message={alert}
                action={
                    <>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert('')}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </div>
    )
}
