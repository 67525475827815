import { gql, useQuery } from "@apollo/client"
import { FillSessionsConnection } from "../../../graphqltypes"
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

export const GET_FILL_SESSIONS = gql`
query GetFillSessions {
  fillSessions(first: 100) {
    edges {
      node {
        id
        station
        gallons
        duration
        filling {
          name
        }
        createdBy {
          name
        }
        startedAt
      }
    }
  }
}
`

export default function FillSessions() {

    const { data, loading } = useQuery<{ fillSessions: FillSessionsConnection }>(GET_FILL_SESSIONS)

    return (
        <div>
            {loading && <LinearProgress />}
            {!loading && data?.fillSessions != null && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Timestamp</TableCell>
                                <TableCell>Station</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Gallons</TableCell>
                                <TableCell>Duration</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.fillSessions?.edges?.map((fs) => (
                                <TableRow key={fs.node?.id}>
                                    <TableCell component="th" scope="row">
                                    {new Intl.DateTimeFormat([], { dateStyle: "full", timeStyle: "short" }).format(new Date(fs.node.startedAt * 1000))}
                                    </TableCell>
                                    <TableCell>{fs.node.station}</TableCell>
                                    <TableCell>{fs.node.filling?.name || "N/A"}</TableCell>
                                    <TableCell>{fs.node.gallons.toFixed(2)}</TableCell>
                                    <TableCell>{display(fs.node.duration)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
}


function display (seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
  
    if (hours < 1) {
        return [minutes, seconds % 60].map(format).join(':')
    }
    return [hours, minutes, seconds % 60].map(format).join(':')
  }