import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { useEffect } from "react";
import { StationStatus } from "../../../graphqltypes";
import Station from "../Station";
import styles from "./index.module.css"

export const GET_STATION_STATUSES = gql`
query GetStationStatuses {
  statuses {
    station
    state
    startedBy {
      name
      id
    }
    userFilling {
      name
      id
    }
    fillLimit
    startedAt
    gallons
  }
}
`

export default function Stations() {
    const { data, loading, startPolling, stopPolling } = useQuery<{ statuses: StationStatus[] }>(GET_STATION_STATUSES)

    useEffect(() => {
        startPolling(3000)
        return () => {
            stopPolling()
        }
    })

    return (
        <div className={styles.holder}>
            {loading && <LinearProgress />}
            {!loading && data?.statuses != null && (
                data.statuses.map(s => (
                    <Station status={s} />
                ))
            )}
        </div>
    )
}