import { gql, useApolloClient } from "@apollo/client"
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Snackbar, TextField } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { useState } from "react"
import { MutationCreateFillSessionArgs, StationStatus } from "../../../graphqltypes"
import { ScopeLocked } from "../ScopeLocked";
import styles from "./index.module.css"

export const CREATE_FILL_SESSION = gql`
mutation CreateFillSession($station: String!, $fillLimit: Float) {
  createFillSession(station: $station, fillLimit: $fillLimit, fillingUser: null)
}
`

export default function Station({ status }: { status: StationStatus }) {
    const client = useApolloClient()
    const [alert, setAlert] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false)
    const [fillLimit, setFillLimit] = useState("")
    const [loading, setLoading] = useState(false)

    const createFillSession = async () => {
        setLoading(true)
        const result = await client.mutate<{ createFillSession: string }, MutationCreateFillSessionArgs>({
            mutation: CREATE_FILL_SESSION,
            variables: {
                station: status.station,
                fillLimit: parseFloat(fillLimit)
            },
            errorPolicy: 'all'
        })

        setDialogOpen(false)
        setLoading(false)
        setFillLimit('')

        if (result?.data?.createFillSession === "successful") {
            setAlert("Fill Session created")
        } else {
            setAlert("Unable to create fill session")
        }

    }

    return (
        <Paper className={styles.card}>
            <Chip label={`Station ${status.station}`} />
            <h1>{(status.gallons || 0).toFixed(2)} gal</h1>
            <span>{status.state.replace("_", " ")}</span>
            <span>{status.state !== "FILLING" && "Last "}Occupied by: {status.userFilling?.name || "N/A"}</span>
            <span>Fill Limit: {status.fillLimit ? `${status.fillLimit.toFixed(2)} gal` : "None"}</span>
            <span>Started filling at: {status.startedAt ? new Intl.DateTimeFormat([], { timeStyle: "short" }).format(new Date(status.startedAt * 1000)) : "N/A"}</span>
            {status.state === "IDLE" && (
                <ScopeLocked requiredScopes={["chlorinestation.dispense-external"]}>
                    <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>Start Fill Session</Button>
                </ScopeLocked>
            )}

            <Dialog open={status.state === "IDLE" && dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Start Fill Session for Station {status.station}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Optionally add a fill limit. If left empty, fill will be unlimited
                    </DialogContentText>
                    <TextField
                        autoFocus
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={fillLimit}
                        onChange={(e) => setFillLimit(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    {!loading ? (
                        <>
                            <Button onClick={() => setDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={createFillSession} color="primary">
                                Create
                            </Button></>
                    ) : (
                        <CircularProgress />
                    )}
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={alert.length > 0}
                autoHideDuration={6000}
                onClose={() => setAlert('')}
                message={alert}
                action={
                    <>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setAlert('')}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </Paper>
    )
}