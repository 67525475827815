import React, { useState } from 'react'
import { Drawer, AppBar, Toolbar, IconButton, Typography, makeStyles, createStyles, Theme, Divider, List, Hidden, Button } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import Tab from './Tab';
import { ScopeLocked } from '../components/ScopeLocked';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
)

export default function TopBar(props: any) {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false)

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <Tab name="Stations" path="/stations" />
                <ScopeLocked requiredScopes={["chlorinestation.use-virtual-keypad"]}>
                    <Tab name="Virtual Keypad" path="/virtual-keypad" />
                </ScopeLocked>
                <ScopeLocked requiredScopes={["chlorinestation.see-logs"]}>
                    <Tab name="Logs" path="/logs" />
                </ScopeLocked>
            </List>
        </div>
    );


    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        A-Quality Chlorine
                    </Typography>
                    <Button variant="outlined" style={{marginLeft: "auto"}} onClick={props.logout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor='left'
                        open={mobileOpen}
                        onClick={handleDrawerToggle}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}