import { ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache } from "@apollo/client"
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import React from "react"
import { CssBaseline, Snackbar, StylesProvider, ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme"
import Login from "./components/Login";
import Entrypoint from "./Entrypoint";


const LOGOUT = gql`
mutation Logout {
  logout
}
`

class App extends React.Component<{}, { token: string, scope: string[], networkError: boolean }> {
    constructor(props: any) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('token') ?? '',
            scope: window.localStorage.getItem('scope')?.split(' ') ?? [],
            networkError: false
        }
    }

    setToken = (token: string, scope: string[]) => {
        this.setState({
            token: token,
            scope: scope
        })
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('scope', scope.join(' '))
    }

    hasToken = () => this.state.token !== ''


    httpLink = createHttpLink({
        uri: 'https://chlorine.a-qualitypools.net/api/graphql',
    })

    errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => {
                if (message === "not enough permissions" && operation.operationName !== "Logout") {
                    this.logout()
                } else {
                    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                }
            })

        if (networkError) console.error(`[Network error]: ${networkError}`);
    })

    authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: `User ${this.state.token}`
            }
        }
    })

    client = new ApolloClient({
        link: this.authLink.concat(this.errorLink).concat(this.httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                errorPolicy: "all"
            }
        }
    })

    logout = async () => {
        try {
            await this.client.mutate({ mutation: LOGOUT })
        } finally {
            this.client.cache.reset()
            this.setToken('', [])
            window.localStorage.clear()
        }
    }

    render() {
        const Base = (props: any) => (
            <ApolloProvider client={this.client}>
                <StylesProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Router>
                            {props.children}
                        </Router>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.networkError}
                            autoHideDuration={6000}
                            onClose={() => this.setState({ networkError: false })}
                            message="A network error ocurred. Please try again later"
                        />
                    </ThemeProvider>
                </StylesProvider>
                <CssBaseline />

            </ApolloProvider>
        )

        if (!this.hasToken()) {
            return (
                <Base>
                    <Login setToken={this.setToken} />
                </Base>
            )
        }

        return (
            <Base>
                <Entrypoint logout={this.logout} />
            </Base>
        )
    }

}

export default App;
