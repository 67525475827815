import React, { PropsWithChildren } from "react"

export function ScopeLocked(props: PropsWithChildren<{ requiredScopes: string[], inverted?: boolean }>) {
    let inverted = props.inverted
    if (inverted == null) inverted = false
    const scopes = window.localStorage.getItem('scope')?.split(' ') ?? []
    for (const rScope of props.requiredScopes) {
        if (props.inverted ? scopes.includes(rScope) : !scopes.includes(rScope)) {
            return (<></>)
        }
    }
    return <>
        {props.children}
    </>
}
