import { Typography } from '@material-ui/core'
import { useLocation } from 'react-router'
import { useMutation } from '@apollo/client'
import styles from "./index.module.css"
import gql from 'graphql-tag'

const LOGIN_CODE = gql`
mutation LoginCode($code: String!) {
  loginCode(code: $code) {
    token
    expiresAt
    scope
  }
}
`

export default function OauthLogin({ setToken }: { setToken: (token: string, scope: string[]) => void }) {
    const location = useLocation()
    const [login, { data, called }] = useMutation(LOGIN_CODE)

    let message = "An unknown error occurred"
    const query = new URLSearchParams(location.search)
    if (query.get("error") === "access_denied") {
        message = "You do not have access to this page"
    } else if (query.get("code") != null) {
        message = "Loading..."
        if (!called) {
            login({
                variables: {
                    code: query.get("code")
                }
            })
        }
    }

    if (data != null && data.loginCode?.token != null) {
        setToken(data.loginCode.token, data.loginCode.scope)
    }

    return (
        <div className={styles.login_page}>
            <img src="/img/logo.png" alt="" />
            <Typography variant="h2" align="center">Chlorine</Typography>
            <Typography>{message}</Typography>
        </div>
    )
}
